exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-approach-tsx": () => import("./../../../src/pages/approach.tsx" /* webpackChunkName: "component---src-pages-approach-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-testimonials-1-tsx": () => import("./../../../src/pages/testimonials-1.tsx" /* webpackChunkName: "component---src-pages-testimonials-1-tsx" */),
  "component---src-pages-testimonials-2-tsx": () => import("./../../../src/pages/testimonials-2.tsx" /* webpackChunkName: "component---src-pages-testimonials-2-tsx" */),
  "component---src-pages-testimonials-3-tsx": () => import("./../../../src/pages/testimonials-3.tsx" /* webpackChunkName: "component---src-pages-testimonials-3-tsx" */)
}

